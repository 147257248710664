<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="ico"
    viewBox="0 0 16 16"
    role="img"
    aria-labelledby="LinkedinIcoTitle"
  >
    <title id="LinkedinIcoTitle">LinkedIn logo</title>
    <path
      stroke="none"
      d="M14.8 0H1.2C.5 0 0 .5 0 1.2v13.7c0 .6.5 1.1 1.2 1.1h13.6c.7 0 1.2-.5 1.2-1.2V1.2c0-.7-.5-1.2-1.2-1.2zM4.7 13.6H2.4V6h2.4v7.6zM3.6 5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4V9.9c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
