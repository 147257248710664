<template>
  <div class="footer">made in 2022</div>
</template>

<script lang="ts" setup></script>

<style lang="stylus" scoped>
.footer
  display flex
  flex-direction column
  justify-content flex-end
  height 40vh
  font-size 0.8rem
  text-align center
  padding 1rem
  position relative
  color var(--text-color-purple)
</style>
